import styled from "styled-components";

export const StandartButton = styled.button`
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 16px;
  padding: 10px 30px;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 10px 10px;
  border-radius: 25px;
  box-shadow: -1px -1px 2px rgb(255 255 255 / 50%),
    2px 2px 4px rgb(41 47 54 / 80%);
  color: #294063;
`;

export const InactiveButton = styled(StandartButton)`
  background-color: #b7c0c5;
  transition: 0.2s;
`;

export const ActiveButton = styled(StandartButton)`
  box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%),
    inset 4px 4px 8px rgb(41 47 54 / 80%);
  background-color: #f89c49;
  transition: 0.2s;
`;

export const SelectAllButton = styled(StandartButton)`
  background-color: #f89c49;
  color: #294063;

  &:hover {
    color: #fff;
  }

  &:active {
    background-color: #294063;
    color: #f89c49;
  }
`;

export const ResetButton = styled(StandartButton)`
  background-color: #294063;
  color: #b7c0c5;

  &:hover {
    background-color: #294063;
    color: #f89c49;
  }

  &:active {
    background-color: #f89c49;
    color: #294063;
  }
`;
