import styled from "styled-components";
import { generateMedia } from "styled-media-query";

// example media queries
export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;

export const media = generateMedia({
  sm: `${BREAKPOINT_SM}px`,
  md: `${BREAKPOINT_MD}px`,
  lg: `${BREAKPOINT_LG}px`,
  xl: `${BREAKPOINT_XL}px`,
});

export const MainContainer = styled.main`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${media.greaterThan("sm")`
 max-width: 540px;
 `};
  ${media.greaterThan("md")`
 max-width: 720px;
 `};
  ${media.greaterThan("lg")`
 max-width: 960px;
 `};
  ${media.greaterThan("xl")`
 max-width: 1140px;
 `};
`;

export const NoContentBlock = styled.div`
  display: flex;
  margin: 60px 0;
  padding: 30px 0;
  background-color: #294063;
  color: #fff;
  justify-content: center;
  border-radius: 40px;

  h3 {
    text-transform: uppercase;
    font-size: 1.75rem;
  }
`;
