import { FooterContainer, FooterContent } from "./Footer.styled";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <p>04053, м. Київ, вул. Кудрявська, 19-А</p>
        <p>Телефон: +38 (044) 201-20-10</p>
        <p>Факс: +38 (044) 201-20-20</p>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
