import React from "react";
import {
  InactiveButton,
  ActiveButton,
  SelectAllButton,
  ResetButton,
} from "./Button.styles";

export const BUTTON_TYPE_CLASSES = {
  inactive: "inactive",
  active: "active",
  selectAll: "selectAll",
  reset: "reset",
};

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base) =>
  ({
    [BUTTON_TYPE_CLASSES.inactive]: InactiveButton,
    [BUTTON_TYPE_CLASSES.active]: ActiveButton,
    [BUTTON_TYPE_CLASSES.selectAll]: SelectAllButton,
    [BUTTON_TYPE_CLASSES.reset]: ResetButton,
  }[buttonType]);

const Button = ({ children, buttonType, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  return <CustomButton {...otherProps}>{children}</CustomButton>;
};

export default Button;
