import { initializeApp } from "firebase/app";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAsXyRX7DfX1XwiuvagPjmn3Ez-5L79Z68",
  authDomain: "ggb-db-bdaa8.firebaseapp.com",
  projectId: "ggb-db-bdaa8",
  storageBucket: "ggb-db-bdaa8.appspot.com",
  messagingSenderId: "196924635528",
  appId: "1:196924635528:web:9656d17dcf151dfd1ce23d",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export const getAllFolders = async () => {
  const storageRef = ref(storage);

  const result = await listAll(storageRef);
  return result.prefixes;
};

export const getFiles = async (folder) => {
  const folderRef = ref(storage, folder);

  const result = await listAll(folderRef);
  return result.items;
};

export const getFileDownloadUrl = async (file) => {
  return getDownloadURL(file);
};
