import styled from "styled-components";

export const NavigationContainer = styled.div`
  margin-top: 25px;
`;

export const BtnSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
