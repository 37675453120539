import { HeaderContainer, SiteHeader } from "./Header.styled";

const Header = () => {
  return (
    <HeaderContainer>
      <SiteHeader>ПрАТ «ГОЛДЕН ГЕЙТ БІЗНЕС»</SiteHeader>
      <p>
        Оптова торгівля деревиною, будівельними матеріалами та
        санітарно-технічним обладнанням
      </p>
      <h5>Директор Шнуров М.Б.</h5>
    </HeaderContainer>
  );
};

export default Header;
