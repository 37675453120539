import { useContext } from "react";

import { DataContext } from "../../contexts/data.context";

import Button, { BUTTON_TYPE_CLASSES } from "../Button/Button.component";

import { NavigationContainer, BtnSection } from "./Navigation.styled";

const Navigation = () => {
  const { foldersMap, setFoldersMap } = useContext(DataContext);

  const toggleButton = (event) => {
    const chosenYear = event.target.name;

    const newFoldersMap = foldersMap.map((folder) => {
      if (folder.year === chosenYear) {
        folder.isActive = !folder.isActive;
      }
      return folder;
    });
    setFoldersMap(newFoldersMap);
  };

  const sellectAll = () => {
    const newFoldersMap = foldersMap.map((folder) => {
      folder.isActive = true;
      return folder;
    });
    setFoldersMap(newFoldersMap);
  };

  const resetAll = () => {
    const newFoldersMap = foldersMap.map((folder) => {
      folder.isActive = false;
      return folder;
    });
    setFoldersMap(newFoldersMap);
  };

  const setButtonType = (isActive) => {
    return isActive ? BUTTON_TYPE_CLASSES.active : BUTTON_TYPE_CLASSES.inactive;
  };

  return (
    <NavigationContainer>
      <BtnSection>
        {foldersMap &&
          foldersMap.map((folder) => (
            <Button
              key={folder.year}
              buttonType={setButtonType(folder.isActive)}
              name={folder.year}
              onClick={toggleButton}
            >
              {folder.year}
            </Button>
          ))}
      </BtnSection>

      <BtnSection>
        <Button buttonType={BUTTON_TYPE_CLASSES.selectAll} onClick={sellectAll}>
          Вибрати всі
        </Button>
        <Button buttonType={BUTTON_TYPE_CLASSES.reset} onClick={resetAll}>
          Скинути вибір
        </Button>
      </BtnSection>
    </NavigationContainer>
  );
};

export default Navigation;
