import styled from "styled-components";

export const FooterContainer = styled.footer`
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background: linear-gradient(
    180deg,
    rgba(91, 106, 122, 0.7) 0%,
    rgba(87, 97, 116, 0.7) 100%
  );
  color: #fff;
`;

export const FooterContent = styled.div`
  width: fit-content;
  text-align: center;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
