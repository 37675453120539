import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BallTriangle } from "react-loader-spinner";

import { LoaderContainer } from "./Loader.styles";

const Loader = () => {
  return (
    <LoaderContainer>
      <BallTriangle color="#294063" height={120} width={120} />
    </LoaderContainer>
  );
};

export default Loader;
