import styled from "styled-components";

export const FileLinkContainer = styled.a`
  display: flex;
  margin: 15px;
  background-color: rgb(255, 255, 255);
  color: #294063;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: inset 0 0 5px #294063;
  justify-content: space-between;
`;

export const FileName = styled.span`
  width: fit-content;
  padding: 10px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const FileType = styled.span`
  background-color: #294063;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;
