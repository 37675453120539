import { useContext, useState, useEffect } from "react";
import { DataContext } from "../../contexts/data.context";
import ArchiveBlock from "../ArchiveBlock/ArchiveBlock";
import Navigation from "../Navigation/Navigation";

import { MainContainer, NoContentBlock } from "./MainSection.styles";

const MainSection = () => {
  const { foldersMap } = useContext(DataContext);
  const [foldersToShow, setFoldersToShow] = useState([]);

  useEffect(() => {
    const folderMapToShow = foldersMap.filter((folder) => folder.isActive);

    setFoldersToShow(folderMapToShow.reverse());
  }, [foldersMap]);

  return (
    <MainContainer>
      <Navigation />
      {foldersToShow.length > 0 ? (
        foldersToShow.map((folder) => (
          <div key={folder.year}>
            <ArchiveBlock files={folder.files} year={folder.year} />
          </div>
        ))
      ) : (
        <NoContentBlock>Не вибрано жодного року</NoContentBlock>
      )}
    </MainContainer>
  );
};

export default MainSection;
