import { createContext, useState, useEffect } from "react";

import {
  getAllFolders,
  getFiles,
  getFileDownloadUrl,
} from "../utils/firebase.utils";

const setActiveBasedOnYear = (year) => {
  const currentYear = new Date().getFullYear();

  try {
    const yearToCompare = parseInt(year);
    if (currentYear - yearToCompare > 2) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log(error.massage);
    return false;
  }
};

const getNameAndFileType = (filename) => {
  const splitedFileName = filename.split(".");
  const fileType = splitedFileName.at(-1);
  const newFileName = filename.replace("." + fileType, "");
  return { fileName: newFileName, fileType: fileType };
};

export const DataContext = createContext({
  foldersMap: [],
  setFoldersMap: () => [],
  isLoad: false,
});

export const DataProvider = ({ children }) => {
  const [foldersMap, setFoldersMap] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    const getFoldersArchive = async () => {
      const allFoldersRefs = await getAllFolders();

      const allFolders = await Promise.all(
        allFoldersRefs.map(async (folderRef) => {
          const filesRef = await getFiles(folderRef);

          const filesInFolder = await Promise.all(
            filesRef.map(async (filesRef) => ({
              fileName: getNameAndFileType(filesRef.name).fileName,
              fileLink: await getFileDownloadUrl(filesRef),
              fileType: getNameAndFileType(filesRef.name).fileType,
            }))
          );

          return {
            year: folderRef.name,
            isActive: setActiveBasedOnYear(folderRef.name),
            files: filesInFolder,
          };
        })
      );
      setFoldersMap(allFolders);
      setIsLoad(true);
    };

    // setIsLoad(true);
    getFoldersArchive();
  }, []);

  const value = { foldersMap, setFoldersMap, isLoad };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
