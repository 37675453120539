import styled from "styled-components";

import { media } from "../MainSection/MainSection.styles";

export const ArchiveSection = styled.section`
  margin: 40px 0px;
  display: flex;
  flex-wrap: wrap;
`;

export const SectionHeader = styled.div`
  width: 100%;
  height: 60px;
  writing-mode: horizontal-tb;
  transform: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  position: relative;
  text-align: center;
  background: #294063;
  color: #f89c49;

  ${media.greaterThan("md")`
  width: 80px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 0px;
  height: auto;
`};
  h2 {
    margin-top: 8px;
  }
`;

export const SecionContent = styled.div`
  padding: 0;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  flex: 1;
  background-color: #b7c0c5;

  ${media.greaterThan("md")`
  padding: 20px 10px 20px 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0px;
`};
`;
