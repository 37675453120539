import { useContext } from "react";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader.component";
import MainSection from "./components/MainSection/MainSection";

import { DataContext } from "./contexts/data.context";

function App() {
  const { isLoad } = useContext(DataContext);
  return (
    <div className="App">
      <Header />

      {isLoad ? <MainSection /> : <Loader />}

      <Footer />
    </div>
  );
}

export default App;
