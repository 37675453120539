import styled from "styled-components";
import mainImg from "./ggb_logo.jpg";

export const HeaderContainer = styled.header`
  background: linear-gradient(
      180deg,
      rgba(91, 106, 122, 0.7) 0%,
      rgba(87, 97, 116, 0.7) 100%
    ),
    url(${mainImg}) no-repeat center;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 4rem 2rem;

  h5 {
    font-size: 1.25rem;
  }
`;

export const SiteHeader = styled.h1`
  color: #f0af76;
  text-shadow: -1px 0 black, 0 1px black;
`;
