import React from "react";
import { FileLinkContainer, FileName, FileType } from "./FileLink.styles";

const FileLink = ({ file }) => {
  const { fileLink, fileName, fileType } = file;
  return (
    <FileLinkContainer href={fileLink}>
      <FileName>{fileName}</FileName>
      <FileType>{fileType}</FileType>
    </FileLinkContainer>
  );
};

export default FileLink;
