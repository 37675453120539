import FileLink from "../FileLink/FileLink.component";
import {
  ArchiveSection,
  SecionContent,
  SectionHeader,
} from "./ArchiveBlock.styled";

const ArchiveBlock = ({ files, year }) => {
  return (
    <ArchiveSection>
      <SectionHeader>
        <h2>{year}</h2>
      </SectionHeader>
      <SecionContent>
        {files &&
          files.map((file) => <FileLink key={file.fileName} file={file} />)}
      </SecionContent>
    </ArchiveSection>
  );
};

export default ArchiveBlock;
